import React, { lazy, Suspense } from "react";
import { WrapPageElementBrowserArgs } from "gatsby";

const FirebaseProvider = lazy(
  () => import("./src/components/firebase/FirebaseProvider")
);

export const wrapRootElement = ({ element }: WrapPageElementBrowserArgs) => (
  <Suspense fallback={null}>
    <FirebaseProvider>{element}</FirebaseProvider>
  </Suspense>
);
